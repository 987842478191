(function (angular) {
    angular.module('MyHippoProducer.Controllers').controller('ProducerListController', ProducerListController);
    ProducerListController.$inject = ['$log', '$scope', '$state', '$stateParams', 'APIService', 'UserService', 'spinnerService', 'toaster'];
    function ProducerListController ($log, $scope, $state, $stateParams, APIService, UserService, spinnerService, toaster) {
        $log.info('ProducerListController');
        $scope.canEditProducers = UserService.canEditProducers();

        $scope.currentPage = 1;
        $scope.itemsPerPage = 100;
        $scope.defaultFilters = {
            keyword: '',
            hideHidden: true,
            populateOrg: true,
            organizationId: '',
            sortField: 'updatedAt',
            sortOrder: -1,
            sortReverse: true,
        };
        $scope.searchBarFilled = false;

        const setFilters = () => {
            const stateParamsAreEmpty = Object.values($stateParams).every((value) => _.isEmpty(value));
            const cleanedStateParams = _.omit($stateParams, ['#']);
            return stateParamsAreEmpty ? Object.assign({}, cleanedStateParams, $scope.defaultFilters) : Object.assign({}, $scope.defaultFilters, cleanedStateParams);
        };
        $scope.filters = setFilters();

        $scope.sortProducers = function (sortField) {
            if ($scope.filters.sortField !== sortField) {
                $scope.filters.sortField = sortField;
            }
            $scope.filters.sortOrder *= -1;
            $scope.filters.sortReverse = !$scope.filters.sortReverse;
        };

        $scope.findProducers = function () {
            $scope.filters.offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
            $scope.filters.limit = parseInt($scope.itemsPerPage, 10);
            $scope.filters.populateOrg = true;

            APIService.findProducers($scope.filters).then(function (results) {
                $scope.producers = results.producers;
                $scope.totalItems = results.count;
                $scope.organizations = results.organizations;
            });
        };
        $scope.findProducers();

        $scope.clearKeyWord = () => {
            $scope.filters.keyword = '';
        };

        $scope.clearSearch = () => {
            $scope.filters = Object.assign({}, $scope.defaultFilters);
            $scope.findProducers();
            $state.go('.', $scope.defaultFilters, { location: 'replace' });
        };

        $scope.$watch('filters', (newValue) => {
            // Display the `Clear` button if the current filters are the same as default
            $scope.searchBarFilled = !_.isEqual(_.omit(newValue, ['limit', 'offset']), $scope.defaultFilters);
        }, true);

        $scope.producersSearch = () => {
            $state.go('.', $scope.filters, { location: 'replace' }).then(() => {
                $scope.findProducers();
            });
        };

        $scope.openProducerDetails = function (producerId) {
            $log.info('openProducerDetails', producerId);
            $state.go('portal.producerDetails', {id: producerId});
        };

        let downloadReportInProgress = false;
        $scope.downloadProducersReport = function () {
            spinnerService.show('globalSpinner');
            if (downloadReportInProgress) {
                return;
            }
            downloadReportInProgress = true;
            APIService.downloadProducersReport().then(function (csv) {
                saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), 'producers-report_' + moment().format('YYYY-MM-DD_HH-mm-ss') + '.csv');
                toaster.pop('success', 'Producers Report', 'Successfully downloaded.');
            }).catch(function () {
                toaster.pop('error', 'Producers Report', 'An error occurred while generating the report.');
            }).finally(function () {
                downloadReportInProgress = false;
                spinnerService.hide('globalSpinner');
            });
        };
    }

    angular.module('MyHippoProducer.Controllers').controller('ProducerDetailsController', ProducerDetailsController);
    function ProducerDetailsController ($log, $scope, $state, $stateParams, spinnerService, toaster, WorkflowUtil, APIService, producerData, UserService) {
        $scope.canEditProducers = UserService.canEditProducers();
        $log.info('ProducerDetailsController');
        var vm = this;
        vm.producerId = producerData.producerInfo.user_id;

        $scope.workflow = producerData.workflow;
        $scope.workflowOriginalMode = {};
        $scope.workflowModel = WorkflowUtil.getWorkflowObjectInfo({});

        var workflowCtrl;
        $scope.setWorkflowCtrl = function (wfCtrl) {
            workflowCtrl = wfCtrl;
        };

        $scope.updateData = function (producerInfo) {
            vm.producerInfo = producerInfo;

            // required for workflow controllers
            $scope.workflowOriginalModel = producerInfo;
            $scope.workflowModel = WorkflowUtil.extendWorkflowModel($scope.workflowModel, _.cloneDeep(producerInfo));
            $scope.workflowModel['__trigger_digest'] = Date.now(); // workaround: trigger a digest on workflowModel
        };
        $scope.updateData(producerData.producerInfo);

        vm.save = function () {
            if (!workflowCtrl.getPageCtrl().validate()) {
                toaster.pop('error', 'Producer Info', 'Invalid form');
                return;
            }

            spinnerService.show('globalSpinner');

            var deltaUpdates = {};
            return workflowCtrl.getPageCtrl().getDeltaUpdate(deltaUpdates).then(function () {
                return APIService.updateProducer(vm.producerId || 'create', deltaUpdates);
            }).then(function (producerInfo) {
                if (producerInfo.user_id !== vm.producerId) {
                    return $state.go('portal.producerDetails', {id: producerInfo.user_id});
                }
                $scope.updateData(producerInfo);
                $scope.workflowErrors = [];

                $scope.$broadcast('policyupdate.success');
                toaster.pop('success', 'Producer Info', 'Successfully saved!');
            }, function (errors) {
                $scope.workflowErrors = errors;
                $scope.workflowModel['__trigger_digest'] = Date.now(); // workaround: trigger a digest on the form

                toaster.pop('error', 'Producer', 'An error occurred while saving the producer<br>' + (typeof (errors) === 'string' ? errors : ''), 3000, 'trustedHtml');
                $scope.$broadcast('policyupdate.error');
                throw errors;
            }).finally(function () {
                spinnerService.hide('globalSpinner');
            });
        };

        vm.cancel = function () {
            $state.go('portal.producersList');
        };
    }
})(window.angular);
